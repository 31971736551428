import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import '../assets/sass/main.scss';
import '../assets/sass/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Footer from './Footer';
import SideBar from './Sidebar';
import SocialNetwork from './Social';
import SocialBar from './Social';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreloaded: true,
    };
    this.currentSlug = props.currentSlug
    this.title = props.title
    this.description = props.description
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ isPreloaded: false });
    }, 100);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    const { children, fullMenu } = this.props;
    const { isPreloaded } = this.state;
    return (
          <>
            <Helmet
              title={"Via al Cedro VI - "+this.title}
              meta={[
                { name: 'description', content: this.description},
                { name: 'keywords', content: 'via, al, cedro, VI, apartamentos, cedritos, cecdro, bogotá, bogota, apartaestudios, eco' },
              ]}
            >
              <html lang="es" />
            </Helmet>
            <div
              className={
                isPreloaded
                  ? 'landing main-body is-preload'
                  : 'landing main-body'
              }
            >
              <div id="page-wrapper">
                <SideBar fullMenu={fullMenu} currentSlug={this.currentSlug} />
                <SocialBar />
                {children}
                <Footer />
              </div>
            </div>
          </>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

export const query = graphql`
  query($title: String!) {
    markdownRemark(frontmatter: { title: {eq: $title}}) {
      frontmatter {
        title
        description
      }
    }
  }
`
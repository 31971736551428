import React, { useState } from 'react';
import { Link } from 'gatsby';
import Navigation from './Navigation';

export default function SideBar({ fullMenu, currentSlug }) {
  const [headerOpen, toggleHeader] = useState(false);
  return (
    <header id="header" className={`${fullMenu ? '' : 'alt'}`}>
      <Navigation currentSlug={currentSlug} />
      {/* <Nav onMenuToggle={() => toggleHeader(!headerOpen)} /> */}
    </header>
  );
}

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink } from 'reactstrap';
import { FiMenu } from 'react-icons/fi';
import { stack as Menu } from 'react-burger-menu';

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  }
};
export default class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.currentSlug = props.currentSlug;
    this.state = {
      isOpen: false,
      scrollPositionY: 0,
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  
  handleScroll = () => {
    // + is unary operator, same as Number(window.scrollY)
    const scrollPositionY = +window.scrollY
    return this.setState({ scrollPositionY })
  }

  componentDidMount() {
    // 32 is the number of milliseconds to debounce
    // I picked this because it's approx 1 frame (ie: 16.7ms)
    // You'll want to modulate that to your taste.
    // Add console.logs in handleScroll function to check if its flooding.
    return window.addEventListener('scroll', debounce(this.handleScroll, 16))
  }

  componentWillUnmount() {
    return window.removeEventListener('scroll', debounce(this.handleScroll, 16))
  }

  render() {
    return (
      <div>
        <StaticQuery 
        query={graphql`
          query {
            allMarkdownRemark(filter:{frontmatter:{title: {ne: "###-"}}} sort: {fields: [frontmatter___order], order: ASC }) {
              edges {
                node {
                  fields {
                    slug
                  }
                  frontmatter {
                    title
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <Navbar expand="md" className={(this.state.scrollPositionY)<10 ? 'unscrolled':null}>
            <NavbarBrand href="/">{(this.state.scrollPositionY)<10 ? <div /> : <img src="/img/CDB.png"/>}</NavbarBrand>
            <div className="burguerMenu">
              <Menu className={"burguerMenu"} customBurgerIcon={<FiMenu />}>
                <a href="/"><img src={"/img/CDB.png"} alt="viaalcedrovi" /></a>
                {data.allMarkdownRemark.edges.filter(edge => edge.node.frontmatter.title !== '###-store').map((element, i)=> {
                  return (<a key={i} className="menu-item" href={element.node.fields.slug}>{element.node.frontmatter.title}</a>)
                })}
              </Menu>
            </div>
            <Collapse isOpen={this.state.isOpen} navbar className="collapsedNavbar" >
              <Nav className="ml-auto" navbar>
                {data.allMarkdownRemark.edges.filter(edge => edge.node.frontmatter.title !== '###-store').map((element, i)=> {
                  return (<NavItem key={i} className={element.node.frontmatter.title === this.currentSlug ? 'current' : ''} >
                    <img src={"/img/hoja.png"} alt="viaalcedrovi"/>
                    <NavLink href={element.node.fields.slug}>
                      {element.node.frontmatter.title}
                    </NavLink>
                  </NavItem>)
                })}
              </Nav>
            </Collapse>
          </Navbar>
        )} />
      </div>
    );
  }
}
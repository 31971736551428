import React, { Component } from 'react';

export default class ContactForm extends Component {

  state = {
    name: '',
    email: '',
    number: '',
    message: '',
    _gotcha: '',
  }

  handleChange = e => {
    const target = e.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    let formData = {
      formSender: this.state.name,
      formEmail: this.state.email,
      formNumber: this.state.number,
      formMessage: this.state.message,
    }
    if (
      this.state._gotcha > 1 ||
      formData.formSender.length < 1 ||
      formData.formEmail.length < 1 ||
      formData.formMessage.length < 1
    ) {
      return false
    }

    let formBody = [];

    for (let property in formData) {
        let encodedKey = property;
        let encodedValue = formData[property];
        formBody.push(encodedKey + "=" + encodedValue);
    }

    formBody = formBody.join("&");

    fetch('https://formcarry.com/s/BlOVlaWSDB',{
      crossDomain: true,
      mode:'no-cors',
      method: 'POST',
      headers: {'Accept': 'application/json', 'Content-Type':'application/x-www-form-urlencoded'},
      body: formBody}).then(
        (result) => {return result.text()},
        (error)=> {return error.text()});

    alert("Hola " + formData.formSender + "\nMuy pronto nos estaremos comunicando contigo.")
    this.setState({
      name: '',
      email: '',
      number: '',
      message: '',
      _gotcha: ''
    })
  }

  render() {
    return (
      <div className="contacto">
        <form onSubmit={this.handleSubmit} >
          <input placeholder="Nombre" name="name" type="text" className="form-input" required onChange={this.handleChange} value={this.state.name} />
          <input placeholder="Correo" name="email" type="email" className="form-input" required onChange={this.handleChange} value={this.state.email} />
          <input placeholder="Teléfono" name="number" type="tel" className="form-input" onChange={this.handleChange} value={this.state.number} />
          <textarea placeholder="Mensaje:" name="message" className="form-input" required onChange={this.handleChange} value={this.state.message} />
          <input type="text" name="_gotcha" style={{display:'none'}} onChange={this.handleChange} value={this.state._gotcha} />
          <input type="submit" value="Enviar" />
        </form>
      </div>
    )
  }
}

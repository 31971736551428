module.exports = {
  siteTitle: 'Via Al Cedro VI', // <title>
  siteUrl: 'https://edificioviaalcedrovi.com',
  manifestName: 'Via Al Cedro VI',
  manifestShortName: 'Via Al Cedro VI App', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#2c2c2c',
  manifestThemeColor: '#2c2c2c',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/images/CDV.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Edificio Al Cedro VI',
  subHeading: 'Es  edificio Eco- amigable  de 94  apartamentos ubicado en Cedritos, cuenta con  energía solar para iluminación de todas las zonas comunes y parqueaderos y una planta para el tratamiento de agua lluvia. Viviendo en VIA AL CEDRO VI  estarás ubicado en un sector de alta valorización en Bogotá, en donde tienes el privilegio de estar cerca a centros comerciales y restaurantes a pocos pasos ',
  // social
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-whatsapp',
      image: '/img/wa.png',
      name: 'Whatsapp',
      url: 'https://wa.me/+573045812596',
    },
    {
      style: 'solid',
      icon: 'fa-envelope',
      image: '/img/ml.png',
      name: 'Email',
      url: 'mailto:edificioviaalcedrovi@gmail.com',
    },
    {
      style: 'brands',
      icon: 'fa-instagram',
      image: '/img/ig.png',
      name: 'Instagram',
      url: 'https://instagram.com/edificiovia_alcedrovi',
    },
    { 
      style: 'brands',
      icon: 'fa-facebook',
      image: '/img/fb.png',
      name: 'Facebook',
      url: 'https://facebook.com',
    },
  ],
};

import React from 'react'
import config from '../../config';

export default class SocialBar extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <ul id="social">
                {config.socialLinks.map(social => {
                const { style, icon, image, name, url } = social;
                return (
                    <li key={url}>
                        <a href={url} >
                            <img src={image} />
                        </a>
                    </li>
                );
                })}
            </ul>
        )
    }
}
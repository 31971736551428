import React from 'react';
import { Col, Row } from 'reactstrap';
import config from '../../config';
import ContactForm from './contactForm';
export default function Footer() {
  return (
    <footer id="footer">
      <Row>
        <Col md={6} xs={12}>
          <h2>Agende su Cita</h2>
          <p>VISITE NUESTRA SALA DE VENTAS<br />Horarios de atención Lunes a Domingo:<br />10 am a 12:30 pm<br/>2:30pm a 4:30pm <br />Carrera 12 # 144-11<br />Bogotá, Colombia<br />TELÉFONO<br />(57) 304 581 2596 <br />edificioviaalcedrovi@gmail.com</p>
        </Col>
        <Col><ContactForm /></Col>
      </Row>
      <ul className="icons">
        {config.socialLinks.map(social => {
          const { style, icon, name, url } = social;
          return (
            <li key={url}>
              <a href={url} className={`icon ${style} ${icon}`}>
                <span className="label">{name}</span>
              </a>
            </li>
          );
        })}
      </ul>
      <ul className="copyright">
        <li>&copy; Mirai</li>
        <li>
          Via Al Cedro VI
        </li>
      </ul>
    </footer>
  );
}
